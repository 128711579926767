<script setup lang="ts">
import { createDashboardReportURI, DashboardQuery } from '@dashboard'
import { Product } from '~/composables/useAPI/types'

const companyId = getCurrentDivisionId()
const { unitSize } = useDivisions()
const { $can } = useNuxtApp()

// TOTAL LEASES
const { data: totalLeases, isPending: isPendingTotalLeases } = useApiDashboard(
  companyId,
  DashboardQuery.TOTAL_LEASES,
)
const totalLeasesResults = computed(() => totalLeases.value?.results)

// GLA
const { data: gla, isPending: isPendingGLA } = useApiDashboard(
  companyId,
  DashboardQuery.GLA,
)
const glaResults = computed(() => gla.value?.results)

// WALE
type WaleShowBy = DashboardQuery.WALE | DashboardQuery.WALE_BY_RENT

const waleShowBy = ref<WaleShowBy>(
  $can('product', Product.LEASE_MANAGER)
    ? DashboardQuery.WALE_BY_RENT
    : DashboardQuery.WALE,
)

const waleShowByText = {
  [DashboardQuery.WALE]: 'area',
  [DashboardQuery.WALE_BY_RENT]: 'rent',
} satisfies Record<WaleShowBy, string>
const { data: wale, isPending: isPendingWale } = useApiDashboard(
  companyId,
  waleShowBy,
)
const waleResults = computed(() => wale.value?.results)

// TEMPLATE ID
const templateId = computed(
  () =>
    totalLeases.value?.templateId ||
    gla.value?.templateId ||
    wale.value?.templateId,
)
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <DashboardCardNumber
      title="Current Leases in Portfolio"
      description="Here goes some details."
      :count="totalLeasesResults?.count ?? 0"
      disclaimer="Only current leases"
      :to="
        createDashboardReportURI(
          companyId,
          totalLeasesResults?.link,
          templateId,
        )
      "
      icon="house-document"
      :loading="isPendingTotalLeases"
      class="flex-1"
    />

    <DashboardCardNumber
      title="Total GLA"
      description="Here goes some details."
      help="Gross Lettable Area"
      :count="glaResults?.totalGla?.value ?? 0"
      :suffix="unitSize?.text"
      :disclaimer="`Based on ${glaResults?.totalGla?.count ?? 0} leases`"
      :to="
        createDashboardReportURI(
          companyId,
          glaResults?.totalGla?.link,
          templateId,
        )
      "
      icon="house-location-pin"
      :loading="isPendingGLA"
      :missing-data="gla?.missingData"
      class="flex-1"
    />

    <DashboardCardNumber
      title="WALE"
      :subtitle="`by ${waleShowByText[waleShowBy]}`"
      :help="`Weighted (by ${waleShowByText[waleShowBy]}) average lease expiry term`"
      description="Here goes some details."
      :count="waleResults?.value ?? 0"
      suffix="years"
      :disclaimer="`Based on ${waleResults?.count ?? 0} leases`"
      :to="createDashboardReportURI(companyId, waleResults?.link, templateId)"
      icon="map-house-home"
      :loading="isPendingWale"
      :missing-data="wale?.missingData"
      class="flex-1"
    >
      <template #secondaryTitle>
        <DashboardToggleRentArea
          v-model="waleShowBy"
          :rent-value="DashboardQuery.WALE_BY_RENT"
          :area-value="DashboardQuery.WALE"
        />
      </template>
    </DashboardCardNumber>
  </div>
</template>
